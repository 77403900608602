import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { STATUS_STOP } from 'config/constants'
import Player from 'components/molecules/player'
import { PlayerModuleContainer } from './styled'

const PlayerModule = ({ status }) => (
  <PlayerModuleContainer>{status !== STATUS_STOP && <Player />}</PlayerModuleContainer>
)

PlayerModule.propTypes = {
  status: PropTypes.string,
}

PlayerModule.defaultProps = {
  status: STATUS_STOP,
}

const mapStateToProps = state => ({
  status: state.mediaStatus.status,
})

export default connect(mapStateToProps)(PlayerModule)
