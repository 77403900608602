import styled from 'styled-components'
import githubIcon from 'assets/images/icons/github.svg'

export const RadioSelectorWrap = styled.div`
  ${({ playStatus }) => playStatus !== 'stop' && 'padding-bottom: 50px;'}
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  &::-webkit-scrollbar {
    display: none;
  }
  @media (${({ theme }) => theme.devices.desktop}) {
    ${({ playStatus }) => playStatus !== 'stop' && 'padding-bottom: 80px;'}
  }
`

export const RadioSelectorContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 220px;
  background-color: ${({ theme }) => theme.colors.backgroundMedium};
  @media (${({ theme }) => theme.devices.desktop}) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const RadioItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 15px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  background-color: ${({ theme }) => theme.colors.backgroundMedium};
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.backgroundDarkMedium};
  }
`

export const RadioLogo = styled.img`
  width: 95px;
  min-width: 95px;
  height: 95px;
  min-height: 95px;
  object-fit: contain;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 20px 0 15px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
`

export const RadioTitle = styled.div`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 1.1rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
`

export const Credits = styled.div`
  padding: 50px 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.primary};
`
export const NameApp = styled.h3`
  margin: 0 0 5px;
  font-size: 1.6em;
  font-weight: 700;
`
export const NameDev = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 15px;
  font-size: 1em;
  font-weight: 400;
  flex-direction: column;
  a {
    width: 35px;
    height: 35px;
    background-image: url(${githubIcon});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 0;
    margin-left: 0.2rem;
  }
  @media (${({ theme }) => theme.devices.desktop}) {
    flex-direction: row;
    a {
      width: 25px;
      height: 25px;
    }
  }
`
export const UrlAge = styled.p`
  margin: 0;
  font-size: 0.8em;
  font-weight: 400;
  a {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`
