import styled from 'styled-components'
import camiloBackground from 'assets/images/backgrounds/camilo-loading.png'

export const Loading = styled.div`
  position: fixed;
  z-index: 999999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 25px;
  background-image: url(${camiloBackground});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 700;
  text-shadow: 0 0 10px #000;
`

export const Title = styled.div`
  font-size: 2rem;
  margin: 0 1rem 1rem;
`
export const Message = styled.div`
  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: rotating 1.5s linear infinite;
  width: 3em;
  height: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::after,
  &::before {
    content: ' ';
    position: absolute;
    width: 1em;
    height: 1em;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 100%;
    box-shadow: 0 0 10px #000;
  }
  &::after {
    top: 0;
  }
  &::before {
    bottom: 0;
  }
`
