import React from 'react'
import { applyMiddleware, createStore } from 'redux'
import { ThemeProvider } from 'styled-components'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import rootReducer from 'redux/reducers'
import { theme } from 'config/theme'
import Layout from 'system/layout'

import 'system/App.css'

const store = createStore(rootReducer, applyMiddleware(thunk))

const App = () => (
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <div className='App'>
        <Layout />
      </div>
    </ThemeProvider>
  </Provider>
)

export default App
