import {
  ERROR_MSG_FAIL_FETCH_RADIOS,
  ERROR_MSG_FAIL_STREAMING,
  ERROR_TYPE_FETCH_RADIOS,
  ERROR_TYPE_STREAMING,
} from './constants'

export const getErrorMessage = errorType => {
  switch (errorType) {
    case ERROR_TYPE_STREAMING:
      return ERROR_MSG_FAIL_STREAMING
    case ERROR_TYPE_FETCH_RADIOS:
      return ERROR_MSG_FAIL_FETCH_RADIOS
    default:
      return false
  }
}
