import { API_GET_ALL_RADIOS_ENDPOINT } from 'config/constants'
import * as Actions from 'redux/actions'

export const getAllRadios = async dispatch => {
  dispatch(Actions.setIsLoading(true))

  const response = await fetch(API_GET_ALL_RADIOS_ENDPOINT)
  let json = await response.json()
  json = await json.reverse()
  dispatch(Actions.setAllRadios(json))
  dispatch(Actions.setIsLoading(false))
}
