import React from 'react'
import PropTypes from 'prop-types'
import PauseIcon from '@material-ui/icons/Pause'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import { STATUS_LOADING, STATUS_PAUSE, STATUS_PLAY, STATUS_STOP } from 'config/constants'
import { AutorenewIconRotate, ButtonContainer } from './styled'

const ButtonPlayPause = ({ playStatus, onClick }) => {
  const ButtonIcon = () => {
    switch (playStatus) {
      case STATUS_PLAY:
        return <PauseIcon />
      case STATUS_PAUSE:
        return <PlayArrowIcon />
      default:
        return <AutorenewIconRotate />
    }
  }

  return (
    <ButtonContainer onClick={playStatus !== STATUS_LOADING ? onClick : null}>
      <ButtonIcon />
    </ButtonContainer>
  )
}

ButtonPlayPause.propTypes = {
  playStatus: PropTypes.string,
  onClick: PropTypes.func,
}

ButtonPlayPause.defaultProps = {
  playStatus: STATUS_STOP,
  onClick: () => {},
}

export default ButtonPlayPause
