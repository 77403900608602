import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Actions from 'redux/actions'
import { filter } from 'lodash'
import RadioSelectorView from './view'

const View = ({ radios, mediaStatus, setMediaLoading, setRadio, setIsFullscreen }) => {
  const selectRadio = radio => {
    setMediaLoading()
    setRadio(radio)
    setIsFullscreen(true)
  }

  const getFilteredRadios = () => {
    const { country } = mediaStatus
    const filteredRadios = filter(radios, {
      country,
    })

    return filteredRadios
  }

  return <RadioSelectorView selectRadio={selectRadio} getFilteredRadios={getFilteredRadios} playStatus={mediaStatus} />
}

const mapStateToProps = state => ({
  radios: state.mediaStatus.radios,
  mediaStatus: state.mediaStatus,
})

const mapDispatchToProps = dispatch => ({
  setMediaLoading: () => dispatch(Actions.setMediaLoading()),
  setRadio: radioData => dispatch(Actions.setRadio(radioData)),
  setIsFullscreen: bool => dispatch(Actions.setIsFullscreen(bool)),
})

View.propTypes = {
  radios: PropTypes.array,
  mediaStatus: PropTypes.object,
  setMediaLoading: PropTypes.func,
  setRadio: PropTypes.func,
  setIsFullscreen: PropTypes.func,
}

View.defaultProps = {
  radios: [],
  mediaStatus: 'stop',
  setMediaLoading: () => {},
  setRadio: () => {},
  setIsFullscreen: () => {},
}

export default connect(mapStateToProps, mapDispatchToProps)(View)
