import {
  ERROR_CLEAR,
  ERROR_FETCH_RADIOS,
  ERROR_STREAMING,
  FULLSCREEN_PLAYER,
  IS_LOADING,
  LOADING_MEDIA,
  PAUSE_MEDIA,
  PLAY_MEDIA,
  SET_ALL_RADIOS,
  SET_COUNTRY,
  SET_RADIO,
  STOP_MEDIA,
} from './types'

export const setRadio = radioData => ({
  type: SET_RADIO,
  radioData,
})

export const setMediaPlay = () => ({
  type: PLAY_MEDIA,
})

export const setMediaStop = () => ({
  type: STOP_MEDIA,
})

export const setMediaPause = () => ({
  type: PAUSE_MEDIA,
})

export const setMediaLoading = () => ({
  type: LOADING_MEDIA,
})

export const setIsFullscreen = bool => ({
  type: FULLSCREEN_PLAYER,
  isFullscreen: bool,
})

export const setIsLoading = bool => ({
  type: IS_LOADING,
  isLoading: bool,
})

export const setCountry = countryKey => ({
  type: SET_COUNTRY,
  countryKey,
})

export const setAllRadios = radios => ({
  type: SET_ALL_RADIOS,
  radios,
})

// Errors
export const errorStreaming = () => ({
  type: ERROR_STREAMING,
})

export const errorFetchRadios = () => ({
  type: ERROR_FETCH_RADIOS,
})

export const errorClear = () => ({
  type: ERROR_CLEAR,
})
