import styled from 'styled-components'

export const PlayerContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundMedium};
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: all 0.2s ease;
  border-top: ${({ fullscreen, theme }) => (fullscreen ? '0' : `1px solid ${theme.colors.primary}`)};
  height: ${({ fullscreen }) => (fullscreen ? '100%' : '50px')};
  ${({ background, fullscreen }) =>
    background &&
    fullscreen &&
    `
    background-image: url(${background});
    background-size: cover;
    background-position: center;
  `};
  @media (${({ theme }) => theme.devices.desktop}) {
    height: ${({ fullscreen }) => (fullscreen ? '100%' : '80px')};
  }
`
export const Audio = styled.audio`
  display: none;
`

export const ButtonPlayContainer = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  ${({ fullscreen }) =>
    !fullscreen
      ? 'justify-content: space-between;'
      : `flex-direction: column;
        height: 100%;
        justify-content: center;`};
  ${({ fullscreen, background }) =>
    fullscreen &&
    background &&
    `
        background-color: rgba(0, 0, 0, 0.8);`}
  @media (${({ theme }) => theme.devices.desktop}) {
    padding: 40px 25px;
  }
`

export const ReturnButtonContainer = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  color: ${({ theme }) => theme.colors.primary};
  cursor: pointer;
`
export const LogoFull = styled.img`
  margin: 0 0 1rem;
  object-fit: contain;
  border-radius: 1000px;
  width: 150px;
  height: 150px;
  background-color: ${({ theme }) => theme.colors.white};
  border: 2px solid ${({ theme }) => theme.colors.primary};
`

export const RadioTitle = styled.h4`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 1rem;
  margin: 10px 0;
  font-weight: 700;
  @media (${({ theme }) => theme.devices.desktop}) {
    font-size: 1.2rem;
  }
`
