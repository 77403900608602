import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Actions from 'redux/actions'
import { CUBA_KEY } from 'config/constants'
import CountrySelectorView from './view'

const View = ({ country, setCountry }) => <CountrySelectorView country={country} setCountry={setCountry} />

View.propTypes = {
  country: PropTypes.string,
  setCountry: PropTypes.func,
}

View.defaultProps = {
  country: CUBA_KEY,
  setCountry: () => {},
}

const mapStateToProps = state => ({
  country: state.mediaStatus.country,
})

const mapDispatchToProps = dispatch => ({
  setCountry: countryKey => dispatch(Actions.setCountry(countryKey)),
})

export default connect(mapStateToProps, mapDispatchToProps)(View)
