import React from 'react'
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'
import Dashboard from 'components/pages/dashboard'

const Layout = () => (
  <Router>
    <Switch>
      <Route exact path='/' component={() => <Dashboard />} />
    </Switch>
  </Router>
)
export default Layout
