import React from 'react'
import Logo from 'components/atoms/logo'
import CountrySelector from 'components/atoms/countrySelector'
import { HeaderWrapper } from './styled'

const Header = () => (
  <HeaderWrapper>
    <Logo />
    <CountrySelector />
  </HeaderWrapper>
)

export default Header
