export const STATUS_PLAY = 'PLAY'
export const STATUS_PAUSE = 'PAUSE'
export const STATUS_STOP = 'STOP'
export const STATUS_LOADING = 'LOADING'
export const STATUS_ERROR = 'ERROR'

export const CUBA_KEY = 'cu'
export const SPAIN_KEY = 'sp'

export const API_GET_ALL_RADIOS_ENDPOINT = process.env.REACT_APP_API_RADIOS

export const ERROR_TYPE_STREAMING = 'STREAMING'
export const ERROR_TYPE_FETCH_RADIOS = 'FETCH_RADIOS'
export const ERROR_MSG_FAIL_STREAMING =
  'Lo siento, pero la emisora a la que estás intentando conectarte está experimentando problemas con su streaming. Inténtalo de nuevo más tarde, y en caso de que el problema persista, puedes reportar el error a sj@sjim.dev.'
export const ERROR_MSG_FAIL_FETCH_RADIOS =
  'Lo siento, pero no podemos conectar con los servicios de la aplicación. Inténtalo de nuevo más tarde, y en caso de que el problema persista, puedes reportar el error a sj@sjim.dev.'
