import AutorenewIcon from '@material-ui/icons/Autorenew'
import styled from 'styled-components'

export const AutorenewIconRotate = styled(AutorenewIcon)`
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  animation: rotating 2s linear infinite;
`
export const ButtonContainer = styled.button`
  background-color: ${({ theme }) => theme.colors.primary};
  border: 0;
  border-radius: 1000px;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
`
