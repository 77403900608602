import { IS_LOADING } from 'redux/actions/types'

const initialState = {
  isLoading: true,
}

const siteStatus = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      }
    default:
      return state
  }
}

export default siteStatus
