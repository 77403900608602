import React from 'react'
import { Brand, LogoWrapper } from './styled'

const Logo = () => (
  <LogoWrapper>
    <Brand>Camilo Radio Player</Brand>
  </LogoWrapper>
)

export default Logo
