import React from 'react'
import PropTypes from 'prop-types'
import { CUBA_KEY, SPAIN_KEY } from 'config/constants'
import { CountrySelectorWrap, FormControlStyled, SelectStyled, MenuItemStyled, InputBaseStyled } from './styled'

const CountrySelector = ({ country, setCountry }) => (
  <CountrySelectorWrap>
    <FormControlStyled variant='standard'>
      <SelectStyled
        labelId='demo-simple-select-standard-label'
        id='demo-simple-select-standard'
        value={country}
        onChange={event => setCountry(event.target.value)}
        input={<InputBaseStyled />}
      >
        <MenuItemStyled value={CUBA_KEY}>
          <span role='img' aria-label='Cuba'>
            🇨🇺
          </span>
        </MenuItemStyled>
        <MenuItemStyled value={SPAIN_KEY}>
          <span role='img' aria-label='Spain'>
            🇪🇸
          </span>
        </MenuItemStyled>
      </SelectStyled>
    </FormControlStyled>
  </CountrySelectorWrap>
)

CountrySelector.propTypes = {
  country: PropTypes.string,
  setCountry: PropTypes.func,
}

CountrySelector.defaultProps = {
  country: CUBA_KEY,
  setCountry: () => {},
}

export default CountrySelector
