import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit'
import ButtonPlayPause from 'components/atoms/buttonPlayPause'
import { Audio, ButtonPlayContainer, LogoFull, PlayerContainer, RadioTitle, ReturnButtonContainer } from './styled'

const Player = ({ ...props }) => {
  const {
    status,
    playing: { background, logo, name, title, streaming },
    fullscreen,
    setIsFullscreen,
    startMedia,
    tooglePlay,
  } = props
  const audioTag = useRef(null)

  useEffect(() => {
    startMedia(audioTag)
  }, [startMedia])

  return (
    <PlayerContainer fullscreen={fullscreen} background={background && background}>
      {fullscreen && (
        <ReturnButtonContainer>
          <FullscreenExitIcon onClick={() => setIsFullscreen(false)} />
        </ReturnButtonContainer>
      )}

      <ButtonPlayContainer fullscreen={fullscreen} background={background && background}>
        {fullscreen && <LogoFull src={logo} alt={`Logo ${name}`} />}
        <RadioTitle onClick={() => setIsFullscreen(true)}>{title}</RadioTitle>
        <ButtonPlayPause playStatus={status} onClick={() => tooglePlay(audioTag)} />
      </ButtonPlayContainer>

      <Audio ref={audioTag} src={streaming} controls />
    </PlayerContainer>
  )
}

Player.propTypes = {
  status: PropTypes.string,
  playing: PropTypes.object,
  fullscreen: PropTypes.bool,
  setIsFullscreen: PropTypes.func,
  startMedia: PropTypes.func,
  tooglePlay: PropTypes.func,
}

Player.defaultProps = {
  status: 'stop',
  playing: {},
  fullscreen: () => {},
  setIsFullscreen: () => {},
  startMedia: () => {},
  tooglePlay: () => {},
}

export default Player
