import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as Actions from 'redux/actions'
import { STATUS_PAUSE, STATUS_PLAY } from 'config/constants'
import PlayerView from './view'

const View = ({ ...props }) => {
  const { status, setMediaPause, setMediaPlay, errorStreaming } = props

  const tooglePlay = audioTag => {
    if (status === STATUS_PLAY) {
      setMediaPause()
      audioTag.current.pause()
    } else if (status === STATUS_PAUSE) {
      setMediaPlay()
      audioTag.current.play()
    }
  }

  const startMedia = audioTag => {
    const { current } = audioTag
    current.play()
    current.oncanplay = () => {
      setMediaPlay()
    }
    current.onerror = () => {
      errorStreaming()
    }
  }

  return <PlayerView {...props} tooglePlay={tooglePlay} startMedia={startMedia} />
}

View.propTypes = {
  status: PropTypes.string,
  playing: PropTypes.object,
  fullscreen: PropTypes.bool,
  setMediaLoading: PropTypes.func,
  setMediaPlay: PropTypes.func,
  setMediaPause: PropTypes.func,
  setIsFullscreen: PropTypes.func,
  errorStreaming: PropTypes.func,
}

View.defaultProps = {
  status: 'stop',
  playing: {},
  fullscreen: false,
  setMediaLoading: () => {},
  setMediaPlay: () => {},
  setMediaPause: () => {},
  setIsFullscreen: () => {},
  errorStreaming: () => {},
}

const mapStateToProps = state => ({
  playing: state.mediaStatus.playing,
  status: state.mediaStatus.status,
  fullscreen: state.mediaStatus.fullscreen,
})

const mapDispatchToProps = dispatch => ({
  setMediaLoading: () => dispatch(Actions.setMediaLoading()),
  setMediaPlay: () => dispatch(Actions.setMediaPlay()),
  setMediaPause: () => dispatch(Actions.setMediaPause()),
  setIsFullscreen: bool => dispatch(Actions.setIsFullscreen(bool)),
  errorStreaming: () => dispatch(Actions.errorStreaming()),
})

export default connect(mapStateToProps, mapDispatchToProps)(View)
