import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
`
