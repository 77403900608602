import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as Operations from 'redux/operations'
import { STATUS_ERROR } from 'config/constants'
import Loader from 'components/atoms/loader'
import Notification from 'components/atoms/notification'
import Header from 'components/organisms/header'
import PlayerModule from 'components/organisms/playerModule'
import RadioSelector from 'components/organisms/radioSelector'
import { DashboardContainer } from './styled'

const Dashboard = ({ getAllRadios, isLoading, status }) => {
  useEffect(() => {
    getAllRadios()
  }, [getAllRadios])

  return (
    <>
      <DashboardContainer>
        <Header />
        <RadioSelector />
        <PlayerModule />
      </DashboardContainer>
      {isLoading && <Loader />}
      {status === STATUS_ERROR && <Notification />}
    </>
  )
}

Dashboard.propTypes = {
  getAllRadios: PropTypes.func,
  isLoading: PropTypes.bool,
  status: PropTypes.string,
}

Dashboard.defaultProps = {
  getAllRadios: [],
  isLoading: false,
  status: 'stop',
}

const mapStateToProps = state => ({
  isLoading: state.siteStatus.isLoading,
  status: state.mediaStatus.status,
})

const mapDispatchToProps = dispatch => ({
  getAllRadios: () => Operations.getAllRadios(dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
