import styled from 'styled-components'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputBase from '@material-ui/core/InputBase'

export const CountrySelectorWrap = styled.div``

export const FormControlStyled = styled(FormControl)`
  && {
    border-radius: 5px;
    ${({ theme }) => `
      background-color: ${theme.colors.white};
    `}
  }
`

export const SelectStyled = styled(Select)`
  && {
    &:before {
      border: 0;
    }
    &:after {
      border: 0;
    }
    &:hover {
      border: 0;
    }
  }
`

export const MenuItemStyled = styled(MenuItem)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2rem;
  }
`

export const InputBaseStyled = styled(InputBase)`
  && {
    span {
      font-size: 2rem;
      line-height: 1;
    }
    .MuiSelect-select {
      min-height: unset;
      padding: 0.3rem 1.5rem 0.3rem 0.3rem;
    }
  }
`
