import styled from 'styled-components'

export const DashboardContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundDark};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
`
