import React from 'react'
import { Loading, Message, Title } from './styled'

const Loader = () => (
  <Loading>
    <Title>Camilo Radio Player</Title>
    <Message />
  </Loading>
)

export default Loader
