export const SET_RADIO = 'SET_RADIO'
export const SET_COUNTRY = 'SET_COUNTRY'

export const PLAY_MEDIA = 'PLAY_MEDIA'
export const PAUSE_MEDIA = 'PAUSE_MEDIA'
export const STOP_MEDIA = 'STOP_MEDIA'
export const LOADING_MEDIA = 'LOADING_MEDIA'

export const FULLSCREEN_PLAYER = 'FULLSCREEN_PLAYER'

export const IS_LOADING = 'IS_LOADING'

export const SET_ALL_RADIOS = 'SET_ALL_RADIOS'

export const ERROR_STREAMING = 'ERROR_STREAMING'
export const ERROR_FETCH_RADIOS = 'ERROR_FETCH_RADIOS'
export const ERROR_CLEAR = 'ERROR_CLEAR'
