import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import * as Actions from 'redux/actions'
import { getErrorMessage } from 'config/utils'

const Notification = ({ errorClear, errorType }) => (
  <Dialog open onClose={errorClear} aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
    <DialogContent>
      <DialogContentText id='alert-dialog-description'>{getErrorMessage(errorType)}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={errorClear} color='primary'>
        Aceptar
      </Button>
    </DialogActions>
  </Dialog>
)

Notification.propTypes = {
  errorType: PropTypes.object,
  errorClear: PropTypes.func,
}

Notification.defaultProps = {
  errorType: {},
  errorClear: () => {},
}

const mapStateToProps = state => ({
  errorType: state.mediaStatus.errorType,
})

const mapDispatchToProps = dispatch => ({
  errorClear: () => dispatch(Actions.errorClear()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notification)
