import React from 'react'
import PropTypes from 'prop-types'

import {
  Credits,
  NameApp,
  NameDev,
  RadioItem,
  RadioLogo,
  RadioSelectorContainer,
  RadioSelectorWrap,
  RadioTitle,
  UrlAge,
} from './styles'

const RadioSelector = ({ mediaStatus, getFilteredRadios, selectRadio }) => {
  const renderRadio = radio => {
    const { slug, logo, title } = radio

    return (
      <RadioItem
        key={`key-${slug}`}
        onClick={() => {
          selectRadio(radio)
        }}
      >
        <RadioLogo src={logo} alt={`Logo ${title}`} loading='lazy' />
        <RadioTitle>{title}</RadioTitle>
      </RadioItem>
    )
  }

  return (
    <RadioSelectorWrap playStatus={mediaStatus}>
      <RadioSelectorContainer>{getFilteredRadios()?.map(radio => renderRadio(radio))}</RadioSelectorContainer>
      <Credits>
        <NameApp>Camilo Radio Player</NameApp>
        <NameDev>
          Santiago Jiménez
          <a href='https://github.com/santiagojimenez' target='_blank' rel='noopener noreferrer'>
            github.com/santiagojimenez
          </a>
        </NameDev>
        <UrlAge>
          <a href='https://santiagojimenez.dev' target='_blank' rel='noopener noreferrer'>
            santiagojimenez.dev
          </a>{' '}
          - 2021
        </UrlAge>
        <UrlAge>
          #UnblockCuba
          <span role='img' aria-label='Cuba'>
            🇨🇺
          </span>
        </UrlAge>
      </Credits>
    </RadioSelectorWrap>
  )
}

RadioSelector.propTypes = {
  mediaStatus: PropTypes.string,
  getFilteredRadios: PropTypes.func,
  selectRadio: PropTypes.func,
}

RadioSelector.defaultProps = {
  mediaStatus: 'stop',
  getFilteredRadios: () => {},
  selectRadio: () => {},
}

export default RadioSelector
