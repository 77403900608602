import {
  CUBA_KEY,
  ERROR_TYPE_FETCH_RADIOS,
  ERROR_TYPE_STREAMING,
  STATUS_ERROR,
  STATUS_LOADING,
  STATUS_PAUSE,
  STATUS_PLAY,
  STATUS_STOP,
} from 'config/constants'

import {
  ERROR_CLEAR,
  ERROR_FETCH_RADIOS,
  ERROR_STREAMING,
  FULLSCREEN_PLAYER,
  LOADING_MEDIA,
  PAUSE_MEDIA,
  PLAY_MEDIA,
  SET_ALL_RADIOS,
  SET_COUNTRY,
  SET_RADIO,
  STOP_MEDIA,
} from 'redux/actions/types'

const initialState = {
  status: STATUS_STOP,
  country: CUBA_KEY,
  fullscreen: false,
  playing: {
    background: '',
    logo: '',
    order: '',
    scope: '',
    slug: '',
    streaming: '',
    title: '',
  },
  radios: null,
  errorType: '',
}
const mediaStatus = (state = initialState, action) => {
  switch (action.type) {
    case PLAY_MEDIA:
      return {
        ...state,
        status: STATUS_PLAY,
      }
    case PAUSE_MEDIA:
      return {
        ...state,
        status: STATUS_PAUSE,
      }
    case STOP_MEDIA:
      return {
        ...state,
        status: STATUS_STOP,
      }
    case LOADING_MEDIA:
      return {
        ...state,
        status: STATUS_LOADING,
      }
    case SET_RADIO:
      return {
        ...state,
        playing: {
          background: action.radioData.background,
          logo: action.radioData.logo,
          order: action.radioData.order,
          scope: action.radioData.scope,
          slug: action.radioData.slug,
          streaming: action.radioData.streaming,
          title: action.radioData.title,
        },
      }
    case FULLSCREEN_PLAYER:
      return {
        ...state,
        fullscreen: action.isFullscreen,
      }
    case SET_ALL_RADIOS:
      return {
        ...state,
        radios: action.radios,
      }
    case SET_COUNTRY:
      return {
        ...state,
        country: action.countryKey,
      }
    case ERROR_STREAMING:
      return {
        ...state,
        status: STATUS_ERROR,
        errorType: ERROR_TYPE_STREAMING,
      }
    case ERROR_FETCH_RADIOS:
      return {
        ...state,
        status: STATUS_ERROR,
        errorType: ERROR_TYPE_FETCH_RADIOS,
      }
    case ERROR_CLEAR:
      return {
        ...state,
        status: STATUS_STOP,
        errorType: '',
      }

    default:
      return state
  }
}

export default mediaStatus
