export const theme = {
  colors: {
    textPrimary: '#eee',
    textSecondary: '#46F0FF',
    primary: '#46F0FF',
    backgroundDark: '#161616',
    backgroundDarkMedium: '#1D1D1D',
    backgroundMedium: '#262626',
    backgroundLight: '#767676',
    white: '#fff',
  },
  devices: {
    desktop: 'min-width: 768px',
  },
}
